import type { Store } from "~/models/Store"

export const useCurrentStoreStore = defineStore("currentStore", {
    state: (): { store?: Store } => ({
        store: undefined,
    }),
    actions: {
        async setCurrentStore(store: Store) {
            const { post } = useOFetchCustom("set-active-store")
            post(
                { store: store.id },
                {
                    onSuccess: () => {
                        this.store = store
                        reloadNuxtApp()
                    },
                }
            )
        },
        clearCurrentStore() {
            this.store = undefined
        },
    },
    persist: {
        storage: sessionStorage,
    },
})
